import { h } from "preact";
import { useState } from "preact/hooks";

import { getLanguageName, getColor } from "../../shared/utils";
import Flag from "../../shared/components/Flag";
import options from "../../shared/options";

const Option = ({ language, colors, onClick, active, url, style }) => {
  function handleClick(e) {
    e.preventDefault();
    onClick(language);
  }
  const [hover, setHover] = useState(false);
  const { full_name, with_flags, flag_type } = style;
  const languageName = getLanguageName(language);
  const name = full_name ? languageName : language.toUpperCase();
  const { textColor } = getColor(colors, { active, hover });

  return (
    <div
      data-l={language}
      style={{ ...(!options.switcher_editor && { cursor: "pointer" }) }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      className="language-option"
      {...(active && { "aria-label": `Language selected: ${languageName}` })}
      onClick={handleClick}>
      <a
        tabIndex={0}
        style={{ color: textColor, opacity: active ? 1 : 0.4 }}
        href={url}
        target="_self"
        role="none"
        id={`weglot-language-${language}`}>
        {with_flags && <Flag language={language} flagType={flag_type} />}
        {name}
      </a>
      <div
        className="underline"
        style={{
          backgroundColor: colors.text_hover,
          width: active ? "100%" : "0%",
        }}
      />
    </div>
  );
};

export default Option;
