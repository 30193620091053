import { resize } from "../../shared/utils";
function getCss({ style: { size_scale } }) {
  const scale = initial => resize(initial, size_scale);
  return [
    {
      selector: "",
      declarations: {
        display: "flex",
        gap: `${scale(5)}px`,
      },
    },
    {
      selector: " div.language-option",
      declarations: {
        fontFamily:
          "system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
        lineHeight: `${scale(20)}px`,
        fontSize: `${scale(14)}px`,
        padding: `${scale(5)}px ${scale(10)}px`,
        transitionProperty: "background-color",
        transitionDuration: "0.2s",
        transitionTimingFunction: "linear",
        position: "relative",
      },
    },
    {
      selector: " div.language-option a",
      declarations: {
        fontWeight: "400",
        marginBottom: `5px`,
        textDecoration: "none",
        display: "flex",
      },
    },
    {
      selector: " div.language-option .underline",
      declarations: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        transition: "width 0.3s ease",
        margin: "0 auto",
        marginBottom: "4px",
        height: `${scale(2.5)}px`,
      },
    },
    {
      selector: " div.language-option a img.wg-flag",
      declarations: {
        marginRight: "10px",
        height: "24px",
      },
    },
  ];
}

export default getCss;
